import {useEffect, useState} from "react";

import type {EditableProperty} from "../../../../../types/EditableConstruction";
import type IConstruction from "../../../../construction/types/IConstruction";
import type RenderableEditableProperty from "../../../../../types/RenderableEditableProperty";
import {
  capitalizeFirstLetter,
  toLowerCaseFirstLetter,
} from "../../../../../utils/StringUtils";
import {
  findPropertyByName,
  groupObjectsByProperty,
} from "../../../../../utils/ObjectUtils";

const getOldValueFromConstructionEntity = (
  constructionProp: any,
  ep: EditableProperty,
): string => {
  if (Array.isArray(constructionProp)) {
    const entity = constructionProp.find((ce: any) => ce.id === ep.entityId);
    if (entity !== undefined) {
      return entity[toLowerCaseFirstLetter(ep.propertyName)];
    }
  }
  return constructionProp[toLowerCaseFirstLetter(ep.propertyName)];
};

const groupHasMirroredProps = (values: EditableProperty[]): boolean =>
  values.some(v => v.propertyName === "IsMirrored");

// Note: Will need refactoring if more than one prop will have mirroring
const handleGroupWithMirroredProp = (
  group: EditableProperty[],
  construction: IConstruction,
): RenderableEditableProperty => {
  const constructionProp = findPropertyByName(
    construction,
    group[0].entityName,
  );
  return {
    entity: capitalizeFirstLetter(group[0].entityName),
    property: group[0].propertyName,
    currentValue: `${group[1].propertyValue === "true" ? "-" : ""}${group[0].propertyValue}`,
    oldValue: getOldValueFromConstructionEntity(constructionProp, group[0]),
  };
};

const processGroupedProps = (
  groups: Map<string, EditableProperty[]>,
  construction: IConstruction,
): RenderableEditableProperty[] => {
  const items: RenderableEditableProperty[] = [];
  groups?.forEach(group => {
    if (groupHasMirroredProps(group)) {
      items.push(handleGroupWithMirroredProp(group, construction));
    } else {
      for (let index = 0; index < group.length; index++) {
        const ep = group[index];
        const constructionProp = findPropertyByName(
          construction,
          ep.entityName,
        );

        if (constructionProp === undefined || constructionProp?.length === 0) {
          continue;
        }

        items.push({
          entity: capitalizeFirstLetter(ep.entityName),
          property: ep.propertyName,
          currentValue: ep.propertyValue,
          oldValue: getOldValueFromConstructionEntity(constructionProp, ep),
        });
      }
    }
  });

  return items;
};

const mapEditablePropsToRenderableEditableProps = (
  construction: IConstruction,
  editableProps: EditableProperty[],
): RenderableEditableProperty[] => {
  const groupedProps = groupObjectsByProperty(editableProps, "entityId");
  return processGroupedProps(groupedProps, construction);
};

const useEditablePropertyHook = (
  construction: IConstruction | undefined,
  editableConstructionProperties: any,
): {items: RenderableEditableProperty[]} => {
  const editableProps: EditableProperty[] = editableConstructionProperties;
  const [items, setItems] = useState<RenderableEditableProperty[]>([]);
  useEffect(() => {
    if (construction !== undefined && editableProps.length > 0) {
      setItems(
        mapEditablePropsToRenderableEditableProps(construction, editableProps),
      );
    }
  }, [editableConstructionProperties]);

  return {items};
};

export default useEditablePropertyHook;
